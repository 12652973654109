import React from 'react'
export const Kurthi = () => {
    
    return (
        <div className="bg-lightblue font-sans">
            <header className="bg-pink-200 text-black p-5 text-center">
                <h1 className="relative left-0 text-4xl">Welcome to Women's Clothing</h1>
            </header>
            </div>
    );
};